export const SET_PRODUCT = "setProduct";
export const SET_PRODUCT_TYPE = "setProductType";
export const SET_CART = "setCart";
export const SET_PRODUCT_NEWS = "setProductNews";

export const SET_PRODUCT_DETAIL = "setProductDetail";

export const SET_SEARCH_PRODUCT = "setSearchProduct";
export const CLEAR_SEARCH_PRODUCT= "clearSearchProduct";

export default {
  state: {
    product: [],
    product_type: [],
    cartItems: [],
    product_news: [],
    product_detail: [],
    searchproduct: [],
  },
  getters: {
    getSearchProduct(state) {
      return state.searchproduct;
    },
    getProduct(state) {
      return state.product;
    },
    getProductType(state) {
      return state.product_type;
    },
    getCart(state) {
      return state.cartItems;
    },
    getProductNews(state) {
      return state.product_news;
    },
    getProductDetaiil(state) {
      return state.product_detail;
    },
    cartTotal(state) {
      return state.cartItems.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );
    },
    cartItemCount(state) {
      return state.cartItems.reduce((total, item) => total + item.quantity, 0);
    },
  },
  actions: {
    [SET_SEARCH_PRODUCT](state, payload) {
      state.commit(SET_SEARCH_PRODUCT, payload);
    },
    [SET_PRODUCT](state, payload) {
      state.commit(SET_PRODUCT, payload);
    },
    [SET_PRODUCT_TYPE](state, payload) {
      state.commit(SET_PRODUCT_TYPE, payload);
    },
    [SET_CART](state, payload) {
      state.commit(SET_CART, payload);
    },
    [SET_PRODUCT_NEWS](state, payload) {
      state.commit(SET_PRODUCT_NEWS, payload);
    },
    [SET_PRODUCT_DETAIL](state, payload) {
      state.commit(SET_PRODUCT_DETAIL, payload);
    },
    updateQuantity({ commit }, { id, quantity }) {
      commit("updateQuantity", { id, quantity });
    },
    removeFromCart({ commit }, { id }) {
      commit("removeFromCart", { id });
    },
    clearCart({ commit }) {
      commit("clearCart");
    },
  },
  mutations: {
    [SET_SEARCH_PRODUCT](state, payload) {
      state.searchproduct = payload;
    },
    [SET_PRODUCT](state, payload) {
      state.product = payload;
    },
    [SET_PRODUCT_TYPE](state, payload) {
      state.product_type = payload;
    },
    // [SET_CART](state, payload) {
    //   state.cart = payload;
    // },
    [SET_PRODUCT_NEWS](state, payload) {
      state.product_news = payload;
    },
    [SET_PRODUCT_DETAIL](state, payload) {
      state.product_detail = payload;
    },
    
    addToCart(state, item) {
      const existingItem = state.cartItems.find(
        (cartItem) => cartItem.id === item.id
      );
      console.log(existingItem);
      if (existingItem) {
        existingItem.quantity += item.quantity;

        console.log(item.quantity);
        console.log("add");
      } else {
        state.cartItems.push(item);
        console.log("new");
      }
    },
    removeFromCart(state, id) {
      const index = state.cartItems.findIndex((cartItem) => cartItem.id === id);
      console.log(index);
      if (index === -1) {
        state.cartItems.splice(index, 1);
      }
    },
    clearProductQuantity(state) {
      state.product_type.forEach((data) => {
        data.products.forEach((data2) => {
          if (data2.quantity) {
            data2.quantity = 0;
          }
        });
      });
      state.product.forEach((data) => {
        if (data.quantity) {
          data.quantity = 0;
        }
      });
    },
    clearCart(state) {
      state.cartItems = [];
      this.commit("clearProductQuantity");
    },
    updateQuantity(state, { id, quantity }) {
      const product = state.cartItems.find((product) => product.id === id);
      product.quantity += quantity;
  
    },
    [CLEAR_SEARCH_PRODUCT](state) {
      state.searchproduct = [];
    },
  },
};
