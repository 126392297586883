<template>
  <div class="vh100 pb-4">

    <div class="sub_header position-relative pt-3 bottom-shadow-box">

      <!-- <div class="back_button_box" @click="$router.go(-1)">
        <img src="~@/assets/back.png" style="height: 20px;" />
      </div> -->


      <div class="main_button_box" @click="go_home">
        <i class="bi bi-house-fill" style="font-size: 22px; color: var(--color);"></i>
      </div>

      <div class="product-title-text text-center">รถเข็น</div>


      <!-- <div class="close_button_box" @click="$router.go(-1)">
        <img src="~@/assets/x.png" style="height: 20px;" />
      </div> -->


    </div>


    <div v-if="Cart_item && Cart_item.length">
      <div class="sub_body text-black w100 mb-2 ">




        <div class="d-flex justify-content-end mb-3">
          <!-- <div class="form-check">
            <input class="form-check-input mt-2" type="checkbox" value="" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
              เลือกทั้งหมด
            </label>
          </div> -->

          <button class="bt-sub-outline" @click="clearCart()">
            ลบทั้งหมด
          </button>

        </div>

        <div class="line" style="background-color: #D9D9D9; width: 100% !important;"></div>


        <div class="position-relative" v-for="cart in Cart_item" :key="cart.id">

          <div class="cart-item mb-3 mt-3">
            <div class="form-check justify-content-start align-item-center d-flex">
              <!-- <input class="form-check-input" type="checkbox" :value='cart.id' id="flexCheckDefault"> -->

              <template v-for="product_pic in cart.picture" :key="product_pic.id">
                <img class="m-0 product-pic" :src='product_pic.picture' v-if="product_pic.cover_image === 1" />
              </template>
              <span style="width: 80%;">
                <div class="row ms-2 mt-2">
                  <div class="col-8">
                    <div class="product-subtitle-text">{{ cart.name }}</div>
                    <!-- <div class="product-detail-text">ตัวเลือกสินค้า</div> -->
                  </div>
                  <div class="col-4 text-end pe-3">


                    <i @click="delete_item(cart.id)" class="bi bi-trash3" style="font-size: 20px;"></i>
                  </div>
                </div>
                <div class="row ms-2">
                  <div class="col-6">
                    <div class="product-subtitle-text">{{ cart.price }} ฿ </div>
                  </div>

                  <div class="button-box mt-3">


                    <button @click="updateCart(cart.id, 1, cart.quantity)">+</button>
                    <span class="count-item">{{ cart.quantity }}</span>
                    <button @click="updateCart(cart.id, -1, cart.quantity)">-</button>
                  </div>

                </div>
              </span>
            </div>
          </div>
          <div class="line" style="background-color: #D9D9D9; width: 100% !important;"></div>
        </div>

      </div>

      <div class="fotter ps-3 pe-3" v-if="Cart_item && Cart_item.length">


        <div class="row mb-3 justify-content-center">

          <div class="col-6 text-center">
            <div class="product-detail-text">
              ยอดสั่งซื้อรวม
            </div>

            <div class="product-sammary-text">
              {{ cartTotal }} ฿
            </div>

          </div>

          <div class="col-6">

            <button class="bt-main" @click="go_summary">
              ซื้อเลย
            </button>
          </div>

        </div>


      </div>

    </div>

    <div v-else>
      <div class="d-flex align-items-center justify-content-center" style="height: 300px;">
        <p class="text-center">ไม่พบข้อมูล</p>
      </div>
    </div>
  </div>
</template>

<script>
import useSweetalert from "@/service/sweetalert2";
import { defineComponent, inject, onMounted, computed } from "vue";
export default defineComponent({
  name: "Home",

  setup() {

    const { Sconfirm } = useSweetalert();


    const router = inject("router");
    const store = inject("store");

    // const Cart_item = store.getters.getCart;

    const cartTotal = computed(() => {
      return store.getters.cartTotal
    });

    const go_summary = () => {
      router.push('Summary');
    }

    const go_home = () => {
      router.push('Dashboard');
    }





    const Cart_item = computed(() => {
      return store.getters.getCart
    });


    const clearCart = () => {
      Sconfirm("คุณต้องการลบสินค้าทั้งหมดไช่หรือไม่ ? ", "question", "ยกเลิก", "ตกลง").then(async (result) => {
        if (result.isConfirmed) {

          store.commit('clearCart')
        }
      });
    }

    const delete_item = (id) => {
      Sconfirm("คุณต้องการลบสินค้าหรือไม่ ? ", "question", "ยกเลิก", "ตกลง").then(async (result) => {
        if (result.isConfirmed) {
          store.dispatch('removeFromCart', { id })
        }
      });
    }

    const updateCart = (id, quantity, total) => {
      if (total <= 1 && quantity == -1) {
        delete_item(id);
      } else {
        store.dispatch('updateQuantity', { id, quantity })
      }
    }



    onMounted(() => {

      console.log(store.getters.getCart);
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });







    return {
      Cart_item,
      go_summary,
      updateCart,
      cartTotal,
      delete_item,
      clearCart,
      go_home
    };
  },
});
</script>

<style scoped>
@import "~sweetalert2/dist/sweetalert2.css";
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "~bootstrap-icons/font/bootstrap-icons.css";

/* Button styles */

.circle-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bi-icon {
  font-size: 20px;
  color: var(--color_sub);
}

.button-box {
  display: flex;
  height: 30px;
  width: 110px;
  border: solid 1px #919191;
  border-radius: 20px;
  justify-content: space-between;
  padding: 5px;
}

.button-box button {
  color: black;
  width: 20%;
  height: 20px;
  border-radius: 50%;
  border: solid 1px #dcdddc;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.count-item {
  font-size: 16px;
  text-align: center;
}


.cart-box {
  display: flex;
  height: 50px;
  width: 100%;
  flex-direction: row;
}

/* .cart-box image {
  position: absolute;
  top: 0;
  left: 25px;
  width: 84px;
  height: 67px;
} */

.form-check-label {
  font-size: 16px !important;
}

.sub_header {
  padding: 0;
  width: 100%;
  height: 60px;
  min-height: 60px;
}

.sub_body {
  padding: 16px;
}


.logo_sub {
  position: absolute;
  top: 10px;
  left: 20px;
}

.close_button_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 45px;
  position: absolute;
  top: 6px;
  right: 10px;
}

.back_button_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 45px;
  position: absolute;
  top: 6px;
  left: 10px;
}

.main_button_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 45px;
  position: absolute;
  top: 6px;
  left: 15px;
}


.bt-main {
  width: 100%;
  min-height: 60px;
  background-color: var(--color);
  color: white;
  border-radius: 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-main:hover {
  background-color: #8b9f6d;
}

.bt-main:active {
  background-color: #8b9f6d;
  transition: background-color 0s;
  opacity: 0.5;
}

.bt-sub-outline {
  width: 30%;
  min-height: 30px;
  border: solid 1px var(--color_sub);
  background-color: white;
  color: var(--color_sub);
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-sub-outline:hover {
  background-color: #f3f3f3;
}


.bt-sub-outline:active {
  background-color: #f3f3f3;
  transition: background-color 0s;
  opacity: 0.5;
}

.product-pic {
  width: 80px;
  height: 80px;
}


.product-title-text {
  font-size: 1.5em;
  font-weight: 400;
}

.product-subtitle-text {
  font-size: 1.2em;
  font-weight: 400;
}

.product-detail-text {
  font-size: 1.0em;
  font-weight: 200;
}

.product-sammary-text {
  font-size: 2.5em;
  font-weight: 500;
}
</style>
