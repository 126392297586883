<template>
  <div class="swiper-container">
    <swiper :spaceBetween="30" :centeredSlides="true" :autoplay="{
      delay: 3000,
      disableOnInteraction: false,
      loop: true
    }" :pagination="pagination" :navigation="true" :modules="modules" class="mySwiper">
      <swiper-slide v-for="image in images" :key="image.id">
        <img :src="image.picture" alt="slide.image" class="swiper-slide-image" />
      </swiper-slide>

    </swiper>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';


// Import Swiper styles
import 'swiper/swiper.scss';
import { Autoplay, Pagination, Navigation } from 'swiper';

export default defineComponent({
  name: 'ImageSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {

    console.log(props.images)

    return {
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return `<span class="${className} circle-pagination"></span>`;
        },
      },
      modules: [Autoplay, Pagination, Navigation],
    };
  },
});
</script>

<style scoped>
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-slide img {
  max-width: 100%;
  object-fit: contain;
}

.swiper-pagination-bullet.circle-pagination {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px;
  background-color: #000;
}

.swiper-pagination-bullet-active.circle-pagination {
  background-color: #fff;
}
</style>