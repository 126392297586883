<template>
  <div class="bg vh100" style="background-color: #F5F5F5;">




    <loading :active="isLoading" :can-cancel="false" :is-full-page="true" loader="dots" :color="color"></loading>




    <div class="header position-relative pb-3">

      <img class="position-absolute" src="~@/assets/logo.png" style="width: 140px;top:6px; right: 8px;" />

      <!-- {{ cartItemCount }} -->

      <!-- <div class="cart-box element" @click="go_cart()">
        <img src="~@/assets/cart.png" style="height: 30px;" />
        <div class="cart-count" v-if='cartItemCount > 0'>
          {{ cartItemCount }}
        </div>
      </div> -->



      <div class="profile-box text-start text-white" v-if="Proflie">
        <img :src="Proflieline.pictureUrl" class="profile-image me-2" />
        <span class="font-weight-light" style="font-size: 16px;">
          สวัสดี !<br>
          <div class="font-weight-light" style="font-size: 20px;">
            คุณ {{ Proflie.firstname }}
          </div>
        </span>

      </div>


      <div class="profile-box text-start text-white" v-else>

        <span class="font-weight-light" style="color:#BED630">
          สมัครสมาชิก <br>
          <div class="font-weight-light text" style="font-size: small;">
            <a href="https://www.w3schools.com">
              กดที่นี่
            </a>
          </div>
        </span><img src="~@/assets/profile.png" class="profile-image " />

      </div>



      <div class="col-12 justify-content-center position-absolute bottom-0 pb-1 ps-1 pe-1">

        <div class="form">
          <input type="text" v-model="search_value" class="form-control form-input" placeholder="ค้นหาสินค้า ..">
          <span class="left-pan"><i @click="search_product" class="bi bi-search" style="color: #9ca3af ;"></i></span>
        </div>

      </div>


    </div>
    <div class="body text-white w100 mb-4">



      <div class="image-slider-box mt-1">

        <ImageSlider :images=news_image></ImageSlider>

      </div>



      <div class="line-with-text ps-1">
        <span class="text">หมวดหมู่</span>
        <div class="line"></div>
      </div>




      <div class="container">

        <div class="hide-scrollbar">
          <div class="product-title">
            <template v-for="data in product_type" :key="data.id">

              <a :href="'#' + data.id" v-if="data.products.length">
                <div class="product-item">
                  <div class="product-image">
                    <!-- <img :src="data.image" class="product-image-logo" alt="" /> -->
                    <img :src="data.icon" class="product-image-logo" alt="" />
                  </div>
                  <div class="container" style="height: 50px;">
                    <div class="product-text"
                      style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">
                      {{ data.name }}
                    </div>
                  </div>

                </div>
              </a>
            </template>
          </div>
        </div>
        <div class="scrollbar">
          <div class="scrollbar-st"></div>
        </div>

      </div>



      <div class="line-with-text ps-1">
        <span class="text">สินค้าประจำสัปดาห์</span>
        <div class="line"></div>
      </div>

      


      <Product_scroll_x2row class="mb-3" :data=products_week></Product_scroll_x2row>


      <div class="line-with-text ps-1 text-black">

        
        <span class="text">สินค้าทั้งหมด</span>
        <div class="line"></div>
      </div>

      


      <Product_scroll_x2row class="mb-3" :data=products></Product_scroll_x2row>





      <div class="row">
        <div class="col-12 body-header-image text-center th t2" style="color: #6C8154 !important;">
        </div>
      </div>

      <div class="container" v-for="data in product_type" :key="data.id">

        <div class="line-with-text ps-1" v-if='data.products != ""'>


          <span class="text" :id="data.id">สินค้าหมวด {{ data.name }}</span>
          <div class="line"></div>
        </div>
        <Product_scroll_x :data=data.products></Product_scroll_x>
      </div>

    </div>

    <div class="fotter">

      <div class="row mb-3 justify-content-center ">
        <div style="height: 1px;background: var(--color); width: 60%;"></div>
      </div>
      <div class="row justify-content-center text-white text-center " style="padding-left: 25px; padding-right: 25px;">
        <h2 class="font-weight-light" style="color: var(--color); font-weight: 200;">ตงศิริ ฟาร์ม (Tongsiri Farm)</h2>
        <h6 style="color: var(--color) !important; font-weight: 300;"> 79 หมู่ 3 ต.ทุ่งคอก อ.สองพี่น้อง จ.สุพรรณบุรี 72110
        </h6>
        <h6 style="color: var(--color)!important; font-weight: 300;">โทรศัพท์ : 094 168 4944</h6>
        <h2 style="color: var(--color)!important; margin-top: 10px; font-weight: 200;">www.tongsirifarm.com</h2>
      </div>





    </div>

  </div>
  <transition name="fade">
    <div class="cart-fix" v-if='cartItemCount > 0' @click="go_cart()">

      <i class="bi bi-cart4"></i>
      <!-- <img src="~@/assets/cart.png" style="height: 30px;" /> -->
      <div class="cart-fix-count" v-if='cartItemCount > 0'>
        {{ cartItemCount }}
      </div>
    </div>
  </transition>
</template>

<script>

import useProduct from "@/service/api/product";
import Loading from "vue-loading-overlay";
import useSweetalert from "@/service/sweetalert2";
import ImageSlider from "@/components/ImageSlider";
import Product_scroll_x from "@/components/Product_scroll_x";
import Product_scroll_x2row from "@/components/Product_scroll_x2row";
import { defineComponent, ref, onMounted, inject, computed } from "vue";
import { SET_SEARCH_PRODUCT } from "@/store/modules/Product";
export default defineComponent({
  name: "Home",
  components: { ImageSlider, Product_scroll_x, Product_scroll_x2row, Loading },
  setup() {
    const { Scustom2 } = useSweetalert();
    const store = inject("store");
    const router = inject("router");
    const Proflie = store.getters.getProfire;
    const Proflieline = store.getters.getProfireLine;
    const search_value = ref(null);
    const { searchProduct } = useProduct();
    let isLoading = ref(false);
    const color = process.env.VUE_APP_COLOR;
    const showLoading = ref(false);

    const products = computed(() => {
      return store.getters.getProduct
    });

    const products_week = computed(() => {
      let allProducts = store.getters.getProduct; 
      let filteredProducts = allProducts.filter(product => {
        return product.week_product === 1;
      });

      return filteredProducts;
    });

    const product_type = computed(() => {
      return store.getters.getProductType
    });


    const cartItemCount = computed(() => {
      return store.getters.cartItemCount
    });

    const news_image = store.getters.getProductNews;





    // const images = [
    //   {
    //     id: 1,
    //     image: 'https://picsum.photos/id/1018/800/600',
    //   },
    //   {
    //     id: 2,
    //     image: 'https://picsum.photos/id/1015/800/600',
    //   },
    //   {
    //     id: 3,
    //     image: 'https://picsum.photos/id/1019/800/600',
    //   },
    // ];

    // const product_type = [
    //   {
    //     id: 1,
    //     name: 'ประเภท A',
    //     image: 'https://picsum.photos/id/1019/800/600',

    //   },
    //   {
    //     id: 2,
    //     name: 'ประเภท B',
    //     image: 'https://picsum.photos/id/958/800/600',
    //   },
    //   {
    //     id: 3,
    //     name: 'เประเภท C',
    //     image: 'https://picsum.photos/id/123/800/600',
    //   },
    //   {
    //     id: 4,
    //     name: 'ประเภท D',
    //     image: 'https://picsum.photos/id/569/800/600',
    //   },
    //   {
    //     id: 5,
    //     name: 'ประเภท E',
    //     image: 'https://picsum.photos/id/1000/800/600',
    //   },
    //   {
    //     id: 6,
    //     name: 'ประเภท F',
    //     image: 'https://picsum.photos/id/999/800/600',
    //   },
    //   {
    //     id: 7,
    //     name: 'ประเภท G',
    //     image: 'https://picsum.photos/id/888/800/600',
    //   },
    //   {
    //     id: 8,
    //     name: 'ประเภท H',
    //     image: 'https://picsum.photos/id/777/800/600',
    //   },
    //];




    const check_register = () => {
      showLoading.value = true;
      setTimeout(() => {
        showLoading.value = false;

        // Scustom('<div class="container "><h3>กรุณาสมัครสมาชิก</h3><h5>เพื่อใช้งานฟังก์ชั่นนี้</h5>'
        //   + '<div class="row mt-5">' + '<button id="swal-2-accept" class="swal-confirm-btn text-white">สมัครสมาชิก</button>' + '</div>'
        //   + '<div class="row mt-2">' + '<button id="swal-2-close" class="swal-cancle-btn">สินค้าของเรา</button>' + '</div>'
        //   + '</div>'
        // ).en(async (result) => {

        //   if (result.value) {
        //     console.log('confirm');
        //   } else {
        //     console.log('cancle');
        //   }

        // });



        Scustom2('<h3>กรุณาสมัครสมาชิก</h3> <h5>เพื่อใช้งานฟังก์ชั่นนี้</h5>', "สมัครสมาชิก", "สินค้าของเรา")
          .en(async (result) => {
            if (result.isConfirmed) {
              console.log('confirm');
            } else if (result.dismiss) {
              if (result.dismiss == 'cancle') {
                console.log('cancle');
              } else {
                console.log('close');
              }
            }
          });

      }, 500);
    }

    const search_product = () => {
      let data = {};
      data.search_value = search_value.value;
      console.log(data.search_value);
      if (data.search_value != null) {
        isLoading.value = true;
        searchProduct(data).then(
          (response) => {

            if (response.data.status === true) {
              isLoading.value = false;
              store.dispatch(SET_SEARCH_PRODUCT, response.data.data);
              router.push({ name: "SearchProduct" });
            } else {
              isLoading.value = false;
            }
          });
      }

    }

    const go_cart = () => {
      router.push({ name: "Cart" });
    }



    const open_map = (lat, lng) => {

      window.open(`https://www.google.com/maps?q=${lat},${lng}`, '_blank').focus();
    }



    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }



    });


    return {
      check_register,
      open_map,
      showLoading,
      color,
      news_image,
      product_type,
      Proflie,
      Proflieline,
      products,
      go_cart,
      cartItemCount,
      isLoading,
      search_product,
      search_value,
      products_week
    };
  },
});
</script>

<style scope>
@import "~sweetalert2/dist/sweetalert2.css";
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "~bootstrap-icons/font/bootstrap-icons.css";



.bg {
  background-color: var(--color);
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.image-slider-box {
  width: 100%;
}


.header {
  padding: 0;
  width: 100%;
  background-color: var(--color);
  height: 140px;
  min-height: 140px;

}

.body {
  width: 100%;
  height: auto;
}

.body-header-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("~@/assets/header-img.png");
  background-color: var(--color_sub);
  width: 100%;
  height: 120px;
  padding: 20px 16px 20px 16px;
}

.body-map-image {
  object-fit: cover;
  width: 100%;
  height: auto;
}


/* .logo {
  position: absolute;
  top: 10px;
  right : 5px;
} */

.googlepin {
  position: absolute;
  width: 30px;
  top: 10px;
  left: 6px;
}

.profile-box {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 20px;
  left: 20px;
}


.search-box {
  top: 70px;
  right: 3px;
}

.profile-image {
  width: 65px;
  height: 65px;
  margin-left: 8px;
  background-color: #DFDDDE;
  border-radius: 50%;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.element {
  background-color: #f0f0f0;
  color: #333;
  padding: 10px;
  transition: all 0.2s ease-in-out;
}

/* Style on hover */
.element:hover {
  background-color: #fff;
  color: #6C8154;
}

/* Style on click */
.element:active {
  background-color: #62754b;
  color: #fff;
}



.cart-fix {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-fix i {
  font-size: 30px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.cart-fix-count {
  color: #ffffff;
  font-size: 12px !important;
  bottom: 53px;
  right: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  background-color: var(--color);
  position: fixed;
  border-radius: 50%;
}




.cart-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 70px;
  background-color: #ffffff;
  position: absolute;
  top: 0px;
  right: 20px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.cart-count {
  color: #ffffff;
  font-size: 12px !important;
  top: 14px;
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  background-color: var(--color);
  position: absolute;
  border-radius: 50%;
}


.line-with-text {

  display: flex;
  align-items: center;
  margin-top: 10px;
}

.text {
  color: var(--color);
  margin-right: 10px;
  font-size: 1.5em;
}

.line {
  flex: 1;
  height: 1px;
  background-color: var(--color);
}

.image-container {
  position: relative;
  height: 50px;
}

.image-container-text {
  position: absolute;
  top: 18px;
  left: 17px;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1;
}

.body-text {
  height: 8rem;
  position: relative;
}

.hide-scrollbar {
  position: relative;
  height: 100%;
  overflow-x: auto;
  overflow-y: none;
  scrollbar-width: none;

}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.scrollbar {
  height: 0.25rem;
  border-radius: 0.0625rem;
  position: absolute;
  bottom: 0.3125rem;
  left: calc(50% - 0.75rem);
  width: 1.5rem;
  background: var(--color);
}

.scrollbar-st {
  background: #ee4d2d;
  width: 10.801px;
  transform: translateX(0px);
}

.product-title {
  display: flex;
  flex-direction: row;
  height: 120px;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
  flex-wrap: wrap;
}

.product-item {
  position: relative;
  text-align: center;
  height: 6rem;
  width: 5rem;
}

.product-text {
  position: relative;
  color: black;
}



.product-image {
  position: relative;
}

.product-image-logo {
  border: solid var(--color_sub) 2px;
  vertical-align: bottom;
  display: inline-block;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-bottom: 5px;
  /* border: 1.3px solid #dee2e6;
  border-radius: 15px; */
}


.form {
  position: relative;
}


.form span {
  position: absolute;
  right: 20px;
  top: 6px;
  padding: 2px;
  border-left: 1px solid #d1d5db;
}

.left-pan {
  padding-left: 7px;
}

.left-pan i {
  padding-left: 9px;
}

.form-input {
  height: 35px;
  border-radius: 10px;
}

.form-input:focus {
  box-shadow: none;
  border: none;
}
</style>
