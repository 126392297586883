<template>
  <loading :active="showLoading" :can-cancel="false" :color="color" loader="dots" />
  <div class="vh100 pb-4">

    <div class="sub_header position-relative pb-2">
      <img class="logo_sub" src="~@/assets/logo_small.png" />
      <!-- <div class="cart-box element" @click="go_cart()">
        <img src="~@/assets/cart.png" style="height: 20px;" />
        <div class="cart-count" v-if='cartItemCount > 0'>
          {{ cartItemCount }}
        </div>

      </div> -->

      <div class="back_button_box" @click="$router.go(-1)">
        <img src="~@/assets/back_bt.png" style="height: 20px;" />
      </div>
    </div>
    
    <div class="body text-white w100 mb-4">
      <div class="row justify-content-center">
        <div class="image-slider-box">


          <ImageSlider :images=images></ImageSlider>
        </div>
      </div>


      <div class="row mb-3 justify-content-center ps-4 pe-4 text-black">

        <div class="col-6">

          <!-- <div class="product-title-text">{{ product_detail }}</div> -->

          <div class="product-title-text"> {{ product_detail.name }} </div>
        </div>

        <div class="col-6 text-end">
          <div class="product-title-text">{{ product_detail.price }} ฿</div>
          <!-- <div class="product-sub-title-text"> ราคา/{{ product_detail.product_units.name }}</div> -->
        </div>



      </div>


      <div class="row mb-3 justify-content-center  ps-4 pe-4   text-black top-shadow-box" style="height: 150px;">
        <div class="line-with-text ps-1">
          <span class="product-detail-text">รายละเอียดสินค้า</span><br>
          <div class="ms-1 line" style="background-color: #707070; width: 80% !important;"></div>
        </div>
        <div class="product-detail-text">
          {{ product_detail.detail }}




        </div>
      </div>

      <div class="button-zone gap-1 top-shadow-box">


        <button class="bt-main" @click="buy_it(product_detail)">
          สั่งซื้อ
        </button>


        <button class="bt-sub" @click="add_cart(product_detail)">
          เพิ่มไปรถเข็น
        </button>


      </div>

    </div>


    <div class="fotter pt-2 top-shadow-box">

      <div class="line-with-text ps-1">
        <span class="text">สินค้าแนะนำ</span>
        <div class="line" style="background-color: black; width: 80% !important;"></div>
      </div>

      <Product_scroll_y :data="random_product"></Product_scroll_y>



    </div>

    <transition name="fade">
      <div class="cart-fix" v-if='cartItemCount > 0' @click="go_cart()">

        <i class="bi bi-cart4"></i>
        <!-- <img src="~@/assets/cart.png" style="height: 30px;" /> -->
        <div class="cart-fix-count" v-if='cartItemCount > 0'>
          {{ cartItemCount }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import useProduct from "@/service/api/product";
import useSweetalert from "@/service/sweetalert2";
import ImageSlider from "@/components/ImageSlider";
import Product_scroll_y from "@/components/Product_scroll_y";
import { defineComponent, ref, inject, computed, onMounted } from "vue";
export default defineComponent({
  name: "Product_detail",
  components: { ImageSlider, Product_scroll_y },
  setup() {
    const { Scustom2, Sconfirmv2, SToast } = useSweetalert();
    const router = inject("router");
    const store = inject("store");
    const product_detail = store.getters.getProductDetaiil;

    console.log(product_detail);
    // API
    const { getRandomProduct } = useProduct();

    const cartItemCount = computed(() => {
      return store.getters.cartItemCount
    });

    const random_product = ref(null);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
      getRandomProduct().then(
        (response) => {
          if (response.data.status === "Success") {
            random_product.value = response.data.data;
          }
        });


    });

    const carts = (id) => {
      return store.getters.getCart.find(
        (cartItem) => cartItem.id === id)
    };


    const buy_it = (item) => {
      if (carts(item.id)) {
        router.push({ name: "Cart" });
      } else {
        item.quantity = 1;
        store.commit('addToCart', item)
      }
      router.push({ name: "Cart" });
    }




    const add_cart = (item) => {
      if (carts(item.id)) {
        Sconfirmv2("สินค้านี้ถูกเพิ่มในตระกร้าแล้ว <br> ไปยังหน้าตะกร้าสินค้าหรือไม่ ?", "question", "ยกเลิก", "ตกลง").then(async (result) => {
          if (result.isConfirmed) {
            router.push({ name: "Cart" });
          }

        });
      } else {
        SToast("success", "เพิ่มสินค้าสำเร็จ");
        item.quantity = 1;
        store.commit('addToCart', item)

      }
    }


    const cartTotal = computed(() => {
      return store.getters.cartTotal
    });


    const images = computed(() => {
      //console.log(product_detail);
      let convert_data = product_detail.picture;
      // convert_data.forEach(function (data) {
      //   data.picture = 'https://apiv2.tongsiri.siaminno.co.th/storage/upload/product/products/' + data.picture;
      // }, convert_data);


      //console.log(convert_data);
      return convert_data;
    });




    // const images = [




    //   {
    //     id: 1,
    //     picture: 'https://picsum.photos/id/1018/800/600',
    //   },
    //   {
    //     id: 2,
    //     picture: 'https://picsum.photos/id/1015/800/600',
    //   },
    //   {
    //     id: 3,
    //     picture: 'https://picsum.photos/id/1019/800/600',
    //   },
    // ];






    const color = "#6C8154";
    const showLoading = ref(false);

    const check_register = () => {
      showLoading.value = true;
      setTimeout(() => {
        showLoading.value = false;

        // Scustom('<div class="container "><h3>กรุณาสมัครสมาชิก</h3><h5>เพื่อใช้งานฟังก์ชั่นนี้</h5>'
        //   + '<div class="row mt-5">' + '<button id="swal-2-accept" class="swal-confirm-btn text-white">สมัครสมาชิก</button>' + '</div>'
        //   + '<div class="row mt-2">' + '<button id="swal-2-close" class="swal-cancle-btn">สินค้าของเรา</button>' + '</div>'
        //   + '</div>'
        // ).en(async (result) => {

        //   if (result.value) {
        //     console.log('confirm');
        //   } else {
        //     console.log('cancle');
        //   }

        // });



        Scustom2('<h3>กรุณาสมัครสมาชิก</h3> <h5>เพื่อใช้งานฟังก์ชั่นนี้</h5>', "สมัครสมาชิก", "สินค้าของเรา")
          .en(async (result) => {
            if (result.isConfirmed) {
              console.log('confirm');
            } else if (result.dismiss) {
              if (result.dismiss == 'cancle') {
                console.log('cancle');
              } else {
                console.log('close');
              }
            }
          });

      }, 500);



    }

    const go_cart = () => {
      router.push({ name: "Cart" });
    }


    return {
      cartItemCount,
      check_register,
      showLoading,
      color,
      go_cart,
      product_detail,
      cartTotal,
      random_product,
      add_cart,
      images,
      buy_it,
    };
  },
});
</script>

<style scoped>
@import "~sweetalert2/dist/sweetalert2.css";
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "~bootstrap-icons/font/bootstrap-icons.css";

/* Button styles */

.sub_header {
  padding: 0;
  width: 100%;
  background-color: var(--color);
  height: 60px;
  min-height: 60px;
}

.logo_sub {
  position: absolute;
  top: 10px;
  left: 20px;
}

.back_button_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 45px;
  position: absolute;
  top: 0px;
  right: 10px;
}


.button-zone {
  padding: 16px;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 50px;
}

.button-zone button {
  margin: auto;
}

.bt-main {
  width: 100%;
  min-height: 40px;
  background-color: var(--color);
  color: white;
  border-radius: 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-main:hover {
  background-color: #8b9f6d;
}

.bt-main:active {
  background-color: #8b9f6d;
  transition: background-color 0s;
  opacity: 0.5;
}

.bt-sub {
  width: 100%;
  min-height: 40px;
  border: none;
  background-color: var(--color_sub);
  color: white;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-sub:hover {
  background-color: #b4dc7c;
}


.bt-sub:active {
  background-color: #b4dc7c;
  transition: background-color 0s;
  opacity: 0.5;
}


.product-title-text {
  font-size: 1.5em;
  font-weight: 400;
}

.product-subtitle-text {
  font-size: 1.2em;
  font-weight: 200;
}

.product-detail-text {
  font-size: 1.0em;
  font-weight: 200;
}

.bg {
  background-color: var(--color);
  overflow-y: scroll;
  overflow-x: hidden;
}

.image-slider-box {
  min-height: 180px;
  width: 100%;
}


html,


.body {
  width: 100%;
  height: auto;
}

.body-header-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("~@/assets/header-img.png");
  background-color: var(--color_sub);
  width: 100%;
  height: 120px;
  padding: 20px 16px 20px 16px;
}


.logo {
  position: absolute;
  top: 30px;
  left: 30px;
}

.element {
  background-color: #f0f0f0;
  color: #333;
  padding: 10px;
  transition: all 0.2s ease-in-out;
}

/* Style on hover */
.element:hover {
  background-color: #fff;
  color: #6C8154;
}

/* Style on click */
.element:active {
  background-color: #62754b;
  color: #fff;
}




.body-text {
  height: 16rem;
  position: relative;
}

.hide-scrollbar {
  position: relative;
  height: 100%;
  overflow-y: hidden;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.scrollbar {
  height: 0.25rem;
  border-radius: 0.0625rem;
  position: absolute;
  bottom: 0.3125rem;
  left: calc(50% - 0.75rem);
  width: 1.5rem;
  background: var(--color);
}

.scrollbar-st {
  background: #ee4d2d;
  width: 10.801px;
  transform: translateX(0px);
}

.product-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
}

.product-item {
  width: 25%;
  padding: 0 0.0625rem;
  text-align: center;
  height: 7rem;
}

.product-image {
  position: relative;
}

.product-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
</style>
