import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Dashboard from "@/views/Dashboard.vue";
import Product_detail from "@/views/Product_detail.vue";
import Cart from "@/views/Cart.vue";
import Summary from "@/views/Summary.vue";
import SearchProduct from "@/views/SearchProduct.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },

  {
    path: "/Dashboard",
    name: "Dashboard",
    component: Dashboard,
  },

  {
    path: "/Product_detail",
    name: "Product_detail",
    component: Product_detail,
  },

  {
    path: "/Cart",
    name: "Cart",
    component: Cart,
  },

  {
    path: "/Summary",
    name: "Summary",
    component: Summary,
  },

  {
    path: "/SearchProduct",
    name: "SearchProduct",
    component: SearchProduct,
  },

 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
