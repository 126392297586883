import ApiService from "@/service/ApiService";

export default function useOrder() {


  const sentOrder = async (data) => {
    return await ApiService.post("sentOrder",data);
  };




  return {
    sentOrder,
  };
}
