
export const SET_SEND_LOCATION = "setSendlocation";
export const SET_ADD_LOCATION = "setAddlocation";
export const SET_EDIT_LOCATION = "setEditlocation";
export const CLEAR_SEND_LOACATION = "clearSendlocation";

export default {
  state: {
    sendlocation: {},
    addlocation: {
      'latitude' : process.env.VUE_APP_LAT,
      'longitude' : process.env.VUE_APP_LNG
    },
    editlocation: [],
  },
  getters: {
    getSendlocation(state) {
      return state.sendlocation;
    },
    getAddlocation(state) {
      return state.addlocation;
    },
    getEditlocation(state) {
      return state.editlocation;
    },
  },
  actions: {
    [SET_SEND_LOCATION](state, payload) {
      state.commit(SET_SEND_LOCATION, payload);
    },
    [SET_ADD_LOCATION](state, payload) {
      state.commit(SET_ADD_LOCATION, payload);
    },
    [SET_EDIT_LOCATION](state, payload) {
      state.commit(SET_EDIT_LOCATION, payload);
    },
  },
  mutations: {
    [SET_SEND_LOCATION](state, payload) {
      state.sendlocation = {
        ...state.sendlocation,
        ...payload,
      };
    },
    [CLEAR_SEND_LOACATION](state) {
      state.sendlocation = [];
    },
    [SET_ADD_LOCATION](state, payload) {
      state.addlocation = {
        ...state.addlocation,
        ...payload,
      };
    },
    [SET_EDIT_LOCATION](state, payload) {
      state.editlocation = {
        ...state.editlocation,
        ...payload,
      };
    },
  },
};
