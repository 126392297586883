import ApiService from "@/service/ApiService";

export default function useProduct() {


  const getRandomProduct = async () => {
    return await ApiService.get("get_random_product");
  };

  const searchProduct = async (data) => {
    return await ApiService.post("search_product",data);
  };

  
  return {
    getRandomProduct,
    searchProduct
  };
}
