<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
  <div class="vh100 pb-4" style="background-color: #F5F5F5;">


    <div class="sub_body text-black w100 mb-2 top-shadow-box">
      <div class="form">
        <input type="text" v-model="search_value" class="form-control form-input" placeholder="ค้นหาสินค้า ..">
        <span class="left-pan"><i @click="search_product" class="bi bi-search" style="color: #9ca3af ;"></i></span>
      </div>


      <div class="container d-flex align-items-center justify-content-center" v-if="search_products.length == 0"
        style="min-height: 300px; color: var(--color) !important; font-size: 18px;">
        ไม่พบสินค้า
      </div>
      <Product_scroll_y v-else class="mt-3 mb-3" :data=search_products></Product_scroll_y>


    </div>




  </div>
</template>

<script>
import Product_scroll_y from "@/components/Product_scroll_y";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { defineComponent, onMounted, inject, ref, computed } from "vue";
import { SET_SEARCH_PRODUCT } from "@/store/modules/Product";
import useProduct from "@/service/api/product";
export default defineComponent({
  name: "SearchProduct",
  components: {
    Loading,
    Product_scroll_y
  },
  setup() {
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const store = inject("store");
    const router = inject("router");
    const { searchProduct } = useProduct();
    const search_value = ref(null);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const go_cart = () => {
      router.push({ name: "Cart" });
    }


    const search_products = computed(() => {
      return store.getters.getSearchProduct
    });

    const search_product = () => {
      let data = {};
      data.search_value = search_value.value;
      console.log(data.search_value);
      if (data.search_value != null) {
        isLoading.value = true;
        searchProduct(data).then(
          (response) => {

            if (response.data.status === true) {
              isLoading.value = false;
              store.commit("clearSearchProduct");
              store.dispatch(SET_SEARCH_PRODUCT, response.data.data);
              // SendLocation.value = store.getters.getSendlocation;
            }
            else {
              isLoading.value = false;
            }
          });
      }

    }

    const cartItemCount = computed(() => {
      return store.getters.cartItemCount
    });

    return {
      isLoading,
      color,
      search_products,
      search_product,
      search_value,
      cartItemCount,
      go_cart
    };
  },
});
</script>

<style scoped>
@import "~sweetalert2/dist/sweetalert2.css";
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import "~bootstrap-icons/font/bootstrap-icons.css";

/* Button styles */

.main_button_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 45px;
  position: absolute;
  top: 6px;
  left: 35px;
}


boder-box {
  border: 2px solid var(--color);
  padding: 5px;
}

boder-box {
  border: 2px solid var(--color);
  padding: 5px;
}

.cart-pic {
  height: 100%;
  width: 100px;
  border-radius: 20px;
}

.button-box {
  display: flex;
  height: 30px;
  width: 110px;
  border: solid 1px #919191;
  padding: 5px;
  border-radius: 20px;
  justify-content: space-between;
}

.button-box button {
  text-align: center;
  background-color: white;
  border: solid 1px #919191;
  border-radius: 50%;
  font-size: 16px;
  line-height: 2px;
  text-align: center;
}

.count-item {
  line-height: 20px;
  font-size: 18px;
  width: 30px;
  text-align: center;
}

button-box button {
  text-align: center;
  border: solid 1px #070707;
  border-radius: 50%;
}

.cart-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 40px;
  background-color: #ffffff;
  position: absolute;
  top: 0px;
  right: 60px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.cart-count {
  color: #ffffff;
  font-size: 12px !important;
  top: 2px;
  right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 18px;
  background-color: var(--color);
  position: absolute;
  border-radius: 50%;
}


.form-check-label {
  font-size: 16px !important;
}

.sub_header {
  padding: 0;
  width: 100%;
  height: 60px;
  min-height: 60px;
  background-color: white !important;
}

.sub_body {
  padding: 16px;
}


.logo_sub {
  position: absolute;
  top: 10px;
  left: 20px;
}

.close_button_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 45px;
  position: absolute;
  top: 6px;
  right: 10px;
}

.back_button_box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 45px;
  position: absolute;
  top: 6px;
  left: 10px;
}

.bt-main {
  width: 100%;
  min-height: 60px;
  background-color: var(--color);
  color: white;
  border-radius: 10px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-main:hover {
  background-color: #8b9f6d;
}

.bt-main:active {
  background-color: #8b9f6d;
  transition: background-color 0s;
  opacity: 0.5;
}

.bt-sub-outline {
  width: 30%;
  min-height: 30px;
  border: solid 1px var(--color_sub);
  background-color: white;
  color: var(--color_sub);
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
}

.bt-sub-outline:hover {
  background-color: #f3f3f3;
}


.bt-sub-outline:active {
  background-color: #f3f3f3;
  transition: background-color 0s;
  opacity: 0.5;
}


.product-title-text {
  font-size: 1.5em;
  font-weight: 400;
}

.product-subtitle-text {
  font-size: 1.2em;
  font-weight: 400;
}

.product-detail-text {
  font-size: 1.0em;
  font-weight: 200;
}

.product-sammary-text {
  font-size: 2.5em;
  font-weight: 500;
}


.bt-edit {
  width: 100%;
  min-height: 60px;
  background-color: rgb(255, 255, 255);
  color: black;
  border-radius: 10px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-out;
  border: 1px solid #1d1c1d4d;

}

.bt-edit:hover {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 8%);
}

.bt-edit:active {
  background-color: #e9e9e9;
  transition: background-color 0s;
  opacity: 0.5;
}

.element {
  background-color: #f0f0f0;
  color: #333;
  padding: 10px;
  transition: all 0.2s ease-in-out;
}

/* Style on hover */
.element:hover {
  background-color: #fff;
  color: #6C8154;
}

/* Style on click */
.element:active {
  background-color: #62754b;
  color: #fff;
}



</style>
