<template>
  <div class="container-y justify-content-center">

    <div class="product-box text-black" v-for="product_data in data" :key="product_data.id">
      <img :src=coverImage(product_data.id)>
      <div class="product-detail pt-2">
        <div class="row mt-2">
          <h2 class="col-5 text-truncate text-start">{{ product_data.name }}</h2>
          <h2 class="col-6 text-end">{{ product_data.price }} ฿</h2>

        </div>

        <div class="row ps-1 pe-1">
          <p class="col-12 text-start text-truncate">{{ product_data.detail }}</p>
        </div>

     
        <div class="row ps-2 pe-2 justify-content-center">
          <div class="col-6 p-0">
            <button class="button-product-main" @click="go_detail(product_data)">รายละเอียด</button>

          </div>
          <div class="col-6 p-0">
            <button class="button-product-sub ms-1" @click="add_cart(product_data)"><i class="bi bi-cart-plus"
                style="font-size: 20px;"></i></button>
          </div>

        </div>


      </div>
    </div>

  </div>
</template>

<script>
import useSweetalert from "@/service/sweetalert2";
import { defineComponent, inject } from 'vue';
import { SET_PRODUCT_DETAIL } from "@/store/modules/Product";
export default defineComponent({
  name: 'Product_scroll_x',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { SToast, Sconfirmv2 } = useSweetalert();
    const store = inject("store");
    // console.log(props.data)

    const router = inject("router");

    const go_detail = (data) => {
      store.dispatch(SET_PRODUCT_DETAIL, data);
      router.push({ name: "Product_detail" });
    }

    const carts = (id) => {
      return store.getters.getCart.find(
        (cartItem) => cartItem.id === id)
    };


    const coverImage = (id) => {
      let dataproduct = props.data.filter(
        (item) => item.id === id
      );
      if (dataproduct) {
        let datapic = dataproduct[0].picture.filter(
          (item) => item.cover_image == 1
        );
        return datapic[0].picture
      }
    };



    const add_cart = (item) => {
      if (carts(item.id)) {
        Sconfirmv2("สินค้านี้ถูกเพิ่มในตระกร้าแล้ว <br> ไปยังหน้าตะกร้าสินค้าหรือไม่ ?", "question", "ยกเลิก", "ตกลง").then(async (result) => {
          if (result.isConfirmed) {
            router.push({ name: "Cart" });
          }

        });
      } else {
        SToast("success", "เพิ่มสินค้าสำเร็จ");
        item.quantity = 1;
        store.commit('addToCart', item)

      }
    }



    return {
      go_detail,
      add_cart,
      coverImage
    };


  },


});
</script>

<style scoped></style>