import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default function useSweetalert() {
  const Sconfirm = (text, icon, cancle_bt, confirm_bt) => {
    return Swal.fire({
      reverseButtons: true,
      padding: "1em",
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: "#97CB5B",
      confirmButtonText: confirm_bt,
      cancelButtonText: cancle_bt,
      customClass: {
        container: "font-size-20",
      },
    });
  };

  const Sconfirmv2 = (text, icon, cancle_bt, confirm_bt) => {
    return Swal.fire({
      reverseButtons: true,
      padding: "1em",
      html: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: "#97CB5B",
      confirmButtonText: confirm_bt,
      cancelButtonText: cancle_bt,
      customClass: {
        container: "font-size-20",
      },
    });
  };


  const SconfirmNc = (text, icon, textbt) => {
    return Swal.fire({
      padding: "2em",
      icon: icon,
      html: text,
      buttonsStyling: false,
      confirmButtonText: textbt,
      confirmButtonColor: "#FFFF",
      customClass: {
        confirmButton: "btn fw-bold btn-light-primary",
      },
    });
  };

  const SToast = (icon, title) => {
    const Toast = Swal.mixin({
      toast: true,
      width: '60%',
      position: "top-end",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icon,
      title: title,
    });
  };

  const Sservice = (data) => {
    return Swal.fire({
      padding: "1em",
      title: "Custom Sweet Alert",
      html: "<form>" + data + "</form>",
      showCloseButton: true,
      closeButtonAriaLabel: "x",
      showConfirmButton: false,
      focusConfirm: false,
      preConfirm: () => {
        //const formData = new FormData(document.getElementById("form"));
        //return document.getElementById('button1').value;

        for (var i = 0; i < data.length; i++) {
          document.getElementById("button" + data[i].id).value;
        }
      },
    });
  };

  const Svideos = (title, videoUrl, text) => {
    return Swal.fire({
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },

      padding: "1em",
      title: `<h4>${title}</h4>`,
      html: `<iframe width="300" height="250" src="${videoUrl}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </br><h5 class="mt-1">${text}</h5>`,
      showCloseButton: true,
      closeButtonAriaLabel: "x",
      showConfirmButton: false,
      focusClose: false,
      showCancelButton: false,
      // confirmButtonText: "แจ้งปัญหากดที่นี่",
    });
  };

  const SconfirRule = (textbt, html) => {
    return Swal.fire({
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },

      html: html,
      confirmButtonText: textbt,
      confirmButtonColor: "#3CB34A",
    });
  };

  const Sexservice = (cancle_bt, confirm_bt, html) => {
    return Swal.fire({
      padding: "1em",
      html: html,
      showCancelButton: true,
      confirmButtonColor: "#3CB34A",
      cancelButtonColor: "#EA1F25",
      confirmButtonText: confirm_bt,
      cancelButtonText: cancle_bt,
    });
  };

  const SconfirmV2 = (text, icon, cancle_bt, confirm_bt) => {
    return Swal.fire({
      padding: "1em",
      icon: icon,
      html: text,
      showCancelButton: true,
      confirmButtonColor: "#3CB34A",
      cancelButtonColor: "#EA1F25",
      confirmButtonText: confirm_bt,
      cancelButtonText: cancle_bt,
    });
  };

  const Scheckboxs = (
    cancle_bt,
    confirm_bt,
    validate_text,
    text,
    inputOptions
  ) => {
    return Swal.fire({
      padding: "1em",
      html: text,
      input: "radio",
      inputOptions: inputOptions,
      showCancelButton: true,
      confirmButtonColor: "#3CB34A",
      cancelButtonColor: "#EA1F25",
      confirmButtonText: confirm_bt,
      cancelButtonText: cancle_bt,
      preConfirm: (inputValue) => {
        if (!inputValue) {
          Swal.showValidationMessage(validate_text);
        }
        return inputValue;
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const Sdropdown = (
    cancle_bt,
    confirm_bt,
    validate_text,
    text,
    inputOptions
  ) => {
    return Swal.fire({
      padding: "1em",
      html: text,
      input: "select",
      inputOptions: inputOptions,
      showCancelButton: true,
      confirmButtonColor: "#3CB34A",
      cancelButtonColor: "#EA1F25",
      confirmButtonText: confirm_bt,
      cancelButtonText: cancle_bt,
      preConfirm: (inputValue) => {
        if (!inputValue) {
          Swal.showValidationMessage(validate_text);
        }
        return inputValue;
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const Scustom = (html) => {
    return Swal.fire({
      showClass: {
        popup: "animate__animated animate__fadeInDown",
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp",
      },
      showCloseButton: true,
      closeButtonAriaLabel: "x",
      showConfirmButton: false,
      showDenineButton: false,
      html: html,
    });
  };
  const Scustom2 = (title, bt_1, bt_2) => {
    return Swal.fire({
      padding: "1em",
      title: title,
      showCloseButton: true,
      closeButtonAriaLabel: "x",
      showCancelButton: true,
      showdenyButton: true,
      confirmButtonText: bt_1,
      cancelButtonText: bt_2,
      confirmButtonClass: "swal-confirm-btn",
      cancelButtonClass: "swal-cancle-btn",
    });
  };
  return {
    Sconfirm,
    SToast,
    SconfirmNc,
    Sservice,
    Svideos,
    SconfirRule,
    Sexservice,
    SconfirmV2,
    Scheckboxs,
    Sdropdown,
    Scustom,
    Scustom2,
    Sconfirmv2
  };
}
